import { defineStore } from "pinia";
import Api from "@/services/Api";
import Customer from "@/services/Customer";
import { ref } from 'vue';

export const useCreditnoteStore = defineStore({
	id: "creditnote",
	state: () => ({
		creditnote_list: [],
		show_create_new: false,
		new_creditnote: {},
		customers: [],
		show_customers_list: false,
		search_customer_val: '',
		search_creditnote_val: '',
		show_create_creditnote: false,
		creditnote_types: [],
		filter_by_type: ref(''),
		selected_customer: ref(null)
	}),
	getters: {
		creditnote_will_pay_fully_or_partially_invoices() {
			/* 
			 * List of invoices that will be paid by the credit note 
			 * 1. if no invoice is selected, based on the amount of the credit note, the system will automatically select the invoices 
			 * that can be paid by the credit note 
			 * 2. if one or more invoices are selected, the system will only pay the selected invoices
			 * List of these invoices and the amount that the credit note will pay as an invoice can be paid partially
			 */
			let invoices = []
			let amount = this.new_creditnote.amount
			let selected_invoices = this.new_creditnote.unpaid_invoices?.filter(invoice => invoice.selected)
			if (selected_invoices?.length > 0) {
				invoices = selected_invoices.map(invoice => {
					let paid_amount = amount >= invoice.unpaid_amount ? invoice.unpaid_amount : amount
					let remaining_amount = invoice.unpaid_amount - paid_amount
					amount -= paid_amount
					return {
						...invoice,
						paid_amount,
						remaining_amount
					}
				})
			} else {
				this.new_creditnote?.unpaid_invoices?.forEach(invoice => {
					if (amount > 0) {
						let paid_amount = amount >= invoice.unpaid_amount ? invoice.unpaid_amount : amount
						let remaining_amount = invoice.unpaid_amount - paid_amount
						amount -= paid_amount
						invoices.push({
							...invoice,
							paid_amount,
							remaining_amount
						})
					}
				})
			}

			return invoices
		},

		filtered_customers() {
			const regex = new RegExp(this.search_customer_val, 'i');
			return this.customers.filter(item => regex.test(item.name));
		},
		filtered_creditnotes() {
			let filtered = [...this.creditnote_list]

			// Filter by search
			if (this.search_creditnote_val) {
				filtered = filtered?.filter(note =>
					note.customer_name?.toLowerCase().includes(this.search_creditnote_val.toLowerCase()) ||
					note.comment?.toLowerCase().includes(this.search_creditnote_val.toLowerCase())
				)
			}

			// Filter by type
			if (this.filter_by_type) {
				filtered = filtered.filter(note => note.type_id === parseInt(this.filter_by_type))
			}

			return filtered
		},
		can_create_creditnote() {
			var can_create = false
			if (
				this.new_creditnote.customer?.guid &&
				this.new_creditnote.amount &&
				this.new_creditnote.type_id
			) {
				can_create = true
			}
			return can_create
		}
	},
	actions: {
		async toggle_select_all_invoices() {
			this.new_creditnote.unpaid_invoices.forEach(invoice => {
				invoice.selected = !invoice.selected
			})
			this.total_amount_unpaid_selected_invoices()
		},
		async total_amount_unpaid_selected_invoices() {
			setTimeout(() => {
				this.new_creditnote.amount = 0
				this.new_creditnote.unpaid_invoices.forEach(invoice => {
					if (invoice.selected) {
						this.new_creditnote.amount += invoice.unpaid_amount
					}
				})
			}, 0)
		},
		async get_unpaid_customer_invoices() {
			this.new_creditnote.unpaid_invoices = []
			await Api(true).post("/customer/unpaid/invoices", {
				customer_id: this.new_creditnote.customer_id
			}).then(res => {
				this.new_creditnote.unpaid_invoices = res.data
			})
		},
		async get_creditnotes() {
			await Api(true).post("/creditnote/get")
				.then(res => {
					this.creditnote_list = res.data.credit_notes
					this.creditnote_types = res.data.types
				})
		},
		async toggle_create_new() {
			this.show_create_creditnote = !this.show_create_creditnote
			this.new_creditnote = {}
			// get customer list
			if (this.show_create_creditnote) {
				this.customers = await Customer.getCustomers();

			}
		},
		select_customer(customer) {
			this.selected_customer = customer
			this.new_creditnote.customer = {}
			this.new_creditnote.customer = { ...customer }
			this.new_creditnote.customer_id = customer.guid
			this.search_customer_val = ''
			this.get_unpaid_customer_invoices()
		},
		clear_selected_customer() {
			this.selected_customer = null
			this.new_creditnote.customer = {}
			this.new_creditnote.customer_id = null
			this.search_customer_val = ''
			this.new_creditnote.unpaid_invoices = []
		},
		async create_new_creditnote() {
			var toast = null
			await Api(true).post("/creditnote/create", {
				credit_note: this.new_creditnote,
				to_pay_invoices: this.creditnote_will_pay_fully_or_partially_invoices
			})
				.then(res => {
					this.creditnote_list.unshift(res.data[0])
					this.show_create_creditnote = false
					this.new_creditnote = {}
					if (res.data[0].guid) {
						toast = { message: 'success' }
					}
					this.new_creditnote = {}
				})
			return toast
		}

	}
})
